<template>
    <div v-loading="loading">
      <section>
        <article class="mt-5">
          <div class="container mt-5">
  
            <div class="row w-100 mb-4">
              <div class="col-12 pl-3 px-0 mb-0 text-left">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item :to="{ path: '/ovalspace/os-admin/' }">Home</el-breadcrumb-item>
                  <el-breadcrumb-item :to="{ path: '/ovalspace/os-admin/bookings' }">Bookings</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
  
            <h3><b>Bookings:</b></h3>
            <div
              style="display: flex; align-items:center; justify-content: space-between;"
              class="search_add_section"
            >
              <div style="width: 50%;">
                <input
                  type="text"
                  v-model.trim="filters[0].value"
                  placeholder="Search by Number"
                  class="search_by_input form-control"
                  spellcheck="off"
                />
              </div>
              <div style="margin-left: 20px">
                <el-select
                  v-model="selectedFilter"
                  placeholder="Select"
                  class="d-block"
                  style="height: 40px !important"
                >
                  <el-option
                    v-for="item in filterOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <hr />
  
            <div
              v-loading="loading"
              class="table-responsive mb-5"
            >
              <!-- {{ formattedOrders }} -->
              <data-tables
                :data="formattedOrders"
                :table-props="tableProps"
                :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
                type="expand"
                :filters="filters"
              >
  
                <div slot="empty">
                  <ScaleOut
                    v-if="isLoading && !loadingError"
                    class="mt-3"
                    :background="'#164B70'"
                  />
                  <div
                    @click="getBookings"
                    v-else-if="!isLoading && loadingError"
                    style="cursor: pointer"
                  >
                    Unable to Load bookings Now. Please click here to retry
                  </div>
                  <div v-if="!isLoading && !loadingError">No bookings</div>
                </div>
  
                <el-table-column
                  label="#"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                  width="80px"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.$index + 1 }}</span>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="customer"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  width="auto"
                >
                  <template slot-scope="scope">
                  <span>Room: <strong>{{ scope.row.roomNumber }}</strong></span><br/>
                  <span>Name: <strong>{{ scope.row.firstName }} {{ scope.row.lastName }}</strong></span><br/>
                  <span>No of Adults: <strong>{{ scope.row.numberOfAdults }}</strong></span><br/>
                  <span>No of Children: <strong>{{ scope.row.numberOfChildren }}</strong></span>
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="totalAmount"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    <span><strong></strong> ${{ formatNumber(scope.row.totalAmount) }} </span><br />
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="Time of Check In an Out"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    <small>Check In Date: <strong>{{ formattedDate(scope.row.checkInDate) }}</strong></small><br />
                    <small>Check Out Date: <strong>{{ formattedDate(scope.row.checkOutDate) }}</strong></small><br />
                  </template>
                </el-table-column>
  
                <el-table-column
                  label="paymentStatus"
                  :show-overflow-tooltip="false"
                  label-class-name="text-left"
                  class-name="text-left"
                >
                  <template slot-scope="scope">
                    {{ scope.row.paymentStatus }}
                  </template>
                </el-table-column>
                
                <el-table-column
                    label="bookingStatus"
                    :show-overflow-tooltip="false"
                    label-class-name="text-left"
                    class-name="text-left"
                >
                    <template slot-scope="scope">
                    {{ scope.row.bookingStatus }}
                    </template>
                </el-table-column>
  
                <el-table-column
                  label="Actions"
                  :show-overflow-tooltip="false"
                  label-class-name="text-center"
                  class-name="text-center"
                >
                  <template slot-scope="scope">
                    <el-row style="display: flex; align-items:center; justify-content:center;">
                      <el-button
                        @click="showOrderDetailsDialog(scope.row)"
                        type="success"
                        :disabled="false"
                        round
                      >Details</el-button>
                    </el-row>
                  </template>
                </el-table-column>
  
              </data-tables>
            </div>
  
          </div>
        </article>
      </section>
  
      <OrderDetailsDialog
        :orderDetailsDialogVisible="orderDetailsDialogVisible"
        :orderData="order_data"
        @closeOrderDetailsDialog="closeOrderDetailsDialog"
      />
  
      <EditCountryDialog
        :showRoomEditDialog="showRoomEditDialog"
        :roomCategoryData="roomCategoryData"
        @closeRoomEditDialog="closeRoomEditDialog"
        @re-fresh="getCategories"
      />
  
    </div>
  </template>
  
  <script>
  import ScaleOut from "@/components/backoffice/scale-out-component.vue";
  import OrderDetailsDialog from "../../components/backoffice/dialogs/order-details-dialog.vue";
  import EditCountryDialog from "../../components/backoffice/dialogs/add-to-bookings-dialog";
  import { format, parseISO } from "date-fns";
  export default {
    components: {
      ScaleOut,
      OrderDetailsDialog,
      EditCountryDialog
    },
  
    data() {
      return {
        loading: false,
        isLoading: false,
        orderDetailsDialogVisible: false,
        bookings: [],
        order_data: {},
        showRoomEditDialog: false,
        roomCategoryData: {},
  
        tableData: [],
        tableProps: {
          border: true,
          stripe: true,
        },
  
        filters: [
          {
            prop: "fullname",
            value: "",
          },
        ],
        loadingError: false,
  
        selectedFilter: "Show All",
        filterOptions: [
          "Show All",
          "Show Delivery Only",
          "Show Cash On Delivery Only",
          "Show PickUps Only",
        ],
      };
    },
  
    computed: {
      formattedDate() {
        return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
      },
  
      formattedOrders() {
        if (this.selectedFilter === "Show All") {
          return this.bookings;
        } else if (this.selectedFilter === "Show Delivery Only") {
          return this.bookings.filter((item) => {
            return item.payment_type === "delivery";
          });
        } else if (this.selectedFilter === "Show Cash On Delivery Only") {
          return this.bookings.filter((item) => {
            return item.payment_type === "cash_on_delivery";
          });
        } else if (this.selectedFilter === "Show PickUps Only") {
          return this.bookings.filter((item) => {
            return item.payment_type === "pickup";
          });
        } else {
          return this.bookings;
        }
      },
    },
  
    mounted() {
      this.getBookings();
    },
  
    methods: {
      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      },
        
        showEditRoomCategoryData(roomCategoryData) {
          this.showRoomEditDialog = true;
          this.roomCategoryData = roomCategoryData;
        },
  
        closeRoomEditDialog() {
          this.showRoomEditDialog = false;
        },
  
      showOrderDetailsDialog(orderData) {
        this.orderDetailsDialogVisible = true;
        this.order_data = orderData;
      },
  
      closeOrderDetailsDialog() {
        this.orderDetailsDialogVisible = false;
      },
  
      async getBookings() {
        this.isLoading = true;
        this.isLoadingError = false;
        try {
          const token = JSON.parse(localStorage.getItem("user"));
          let request = await this.$http.get(`bookings`);
          if (
            request.data.success &&
            request.data.message === "BOOKINGS_FETCHED_SUCCESSFULLY"
          ) {
            this.bookings = request.data.bookings;
          } else throw "UNEXPECTED_RESPONSE_RECEIVED";
        } catch (error) {
          this.isLoadingError = true;
          //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
          this.$notify({
            title: "Operation Failed",
            message: "Unable to fetch Orders now, please try again",
            type: "error",
          });
        } finally {
          this.isLoading = false;
        }
      },
  
      async deleteUser(userId) {
        this.$confirm(
          // eslint-disable-line no-unreachable
          "This will permanently delete this Order. Do you want to Continue?",
          "Confirm delete",
          {
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              this.deleting = true;
              let request = await this.$http.delete(`bookings/${userId}`);
              if (
                request.data.success &&
                request.data.message === "USER_DELETED_SUCCESSFULLY"
              ) {
                this.getBookings();
                return this.$notify({
                  title: "Success",
                  message: "Admin User deleted successfully!",
                  type: "success",
                });
              } else {
                throw "UNEXPECTED_RESPONSE";
              }
            } catch (error) {
              if (error.message == "Network Error") {
                this.loadingError = true;
                return this.$notify({
                  title: "Connection failed",
                  message: "A network error occurred, please try again.",
                  type: "error",
                });
              }
              return this.$notify({
                title: "Unable to Delete",
                message: "Unable to delete Admin User, Please try again",
                type: "error",
              });
            } finally {
              this.deleting = false;
            }
          })
          .catch(() => {
            //
          });
      },
    },
  };
  </script>
  
  <style>
  .el-form-item {
    margin-bottom: 0px;
  }
  
  .el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
  }
  label {
    display: inline-block;
    margin-bottom: 0px;
  }
  </style>